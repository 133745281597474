/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");
/*Theme Colors*/
/*bootstrap Color*/
/*Light colors*/
/*Normal Color*/
/*Extra Variable*/
/*Preloader*/
.preloader {
  width: 100%;
  height: 100%;
  top: 0px;
  position: fixed;
  z-index: 99999;
  background: #fff;
}

.preloader .cssload-speeding-wheel {
  position: absolute;
  top: calc(50% - 3.5px);
  left: calc(50% - 3.5px);
}

/*******************
/*Top bar
*******************/
.topbar {
  background: #00897b;
}

.topbar .top-navbar .navbar-header .navbar-brand .dark-logo {
  display: none;
}

.topbar .top-navbar .navbar-header .navbar-brand .light-logo {
  display: inline-block;
  color: rgba(255, 255, 255, 0.8);
}

.topbar .navbar-light .navbar-nav .nav-item > a.nav-link {
  color: rgba(255, 255, 255, 0.8) !important;
}

.topbar .navbar-light .navbar-nav .nav-item > a.nav-link:hover, .topbar .navbar-light .navbar-nav .nav-item > a.nav-link:focus {
  color: #ffffff !important;
}

/*******************
/*General Elements
*******************/
a {
  color: #01c0c8;
}

a.link:hover, a.link:focus {
  color: #01c0c8 !important;
}

.bg-theme {
  background-color: #01c0c8 !important;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #01c0c8;
  border-color: #01c0c8;
}

.right-sidebar .rpanel-title {
  background: #01c0c8;
}

.stylish-table tbody tr:hover, .stylish-table tbody tr.active {
  border-left: 4px solid #01c0c8;
}

.text-themecolor {
  color: #01c0c8 !important;
}

.profile-tab li a.nav-link.active,
.customtab li a.nav-link.active {
  border-bottom: 2px solid #01c0c8;
  color: #01c0c8;
}

.profile-tab li a.nav-link:hover,
.customtab li a.nav-link:hover {
  color: #01c0c8;
}

/*******************
/*Buttons
*******************/
.btn-themecolor,
.btn-themecolor.disabled {
  background: #01c0c8;
  color: #ffffff;
  border: 1px solid #01c0c8;
}

.btn-themecolor:hover,
.btn-themecolor.disabled:hover {
  background: #01c0c8;
  opacity: 0.7;
  border: 1px solid #01c0c8;
}

.btn-themecolor.active, .btn-themecolor:focus,
.btn-themecolor.disabled.active,
.btn-themecolor.disabled:focus {
  background: #028ee1;
}

/*******************
/*sidebar navigation
*******************/
.card-no-border .left-sidebar, .card-no-border .sidebar-nav {
  background: #272c33;
}

.mini-sidebar .sidebar-nav {
  background: transparent;
}

@media (min-width: 768px) {
  .mini-sidebar .sidebar-nav #sidebarnav > li > ul {
    background: #181c22;
  }
}

.sidebar-nav {
  background: #272c33;
}

.sidebar-nav ul li a {
  color: #798699;
}

.sidebar-nav ul li a.active, .sidebar-nav ul li a:hover {
  color: #00897b;
}

.sidebar-nav ul li a.active {
  color: #ffffff;
}

.sidebar-nav ul li.nav-small-cap {
  color: #99abb4;
}

.sidebar-nav > ul > li.active > a, .sidebar-nav > ul > li.active:hover > a {
  color: #ffffff !important;
  background: #00897b !important;
}

.sidebar-nav > ul > li.active > a i, .sidebar-nav > ul > li.active:hover > a i {
  color: #ffffff !important;
}

.sidebar-nav > ul > li.active > a:after, .sidebar-nav > ul > li.active:hover > a:after {
  border-color: #ffffff;
}

.sidebar-nav > ul > li > a.active i, .sidebar-nav > ul > li > a:hover i {
  color: #00897b;
}

.sidebar-nav > ul > li > a i {
  color: #798699;
}

.sidebar-nav > ul > li > a.active {
  font-weight: 400;
  background: #ffffff;
  color: #01c0c8;
}

.user-profile .profile-text a {
  color: #798699 !important;
}

.card-no-border .sidebar-footer {
  background: #181c22;
}
